import styled from "styled-components";
import {
  ManualEntry,
  usePlacesAutocomplete,
} from "../Hooks/usePlacesAutocomplete";
import { AutocompleteBase, OptionProps } from "../melodies-source/Autocomplete";
import { Option } from "../melodies-source/Select";
import { Body2, Li } from "../melodies-source/Text";

interface PlacesAutocompleteProps {
  label?: string;
  initialQuery?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  hasError?: boolean;
  onChange: (
    result: google.maps.GeocoderResult | ManualEntry,
    option: Option,
  ) => void;
}

export const PlacesAutocomplete = ({
  label,
  initialQuery,
  onChange,
  ...props
}: PlacesAutocompleteProps) => {
  const { loading, setQuery, query, results, getAddressDetails } =
    usePlacesAutocomplete({ initialQuery });

  const handleSelect = async (option: OptionProps) => {
    try {
      const result = await getAddressDetails(option);

      if (result) {
        onChange(result, option);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Autocomplete
      setText={setQuery}
      text={query}
      label={label}
      options={results || []}
      onChange={handleSelect}
      isLoading={loading}
      customOption={CustomOption}
      {...props}
    />
  );
};

const CustomOption = ({
  label,
  value,
  data,
  icon,
  isSelected,
  caption,
  ...props
}: OptionProps & { caption?: string }) => {
  return (
    <Row {...props}>
      {icon && <Icon>{icon}</Icon>}
      <div>
        <Li>{label}</Li>
        {caption && <Body2>{caption}</Body2>}
      </div>
    </Row>
  );
};

const Autocomplete = styled(AutocompleteBase)`
  input {
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }

    & + div {
      display: none !important;
    }
  }
`;

const Row = styled.div`
  display: flex;
  padding: 12px;
  cursor: pointer;

  &:hover {
    background: #fafafa;
  }
`;

const Icon = styled.div`
  margin-right: 12px;
  flex-shrink: 0;
  color: ${(p) => p.theme.colors.black60};
`;
