import { KeyRequest } from "@max/common/api";
import {
  DEFAULT_PRODUCTION_PUBLIC_KEY,
  DEFAULT_STAGING_PUBLIC_KEY,
} from "@max/common/crypto";
import { api } from "../api";

export const getPublicKey = async (request: KeyRequest) => {
  try {
    const response = await api.call("secure.getPublicKey", request);

    if (response.data.success) {
      return response.data.data;
    }
  } catch (e) {
    console.warn(e);
  }

  return process.env.NODE_ENV === "production"
    ? DEFAULT_PRODUCTION_PUBLIC_KEY
    : DEFAULT_STAGING_PUBLIC_KEY;
};
