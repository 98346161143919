import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { OptionProps } from "../melodies-source/Autocomplete";
import { SvgLocation } from "../melodies-source/Svgs/Location";
import { googleGeocoder, googlePrediction } from "../Utils/maps";
import useUserIdToken from "./useUserIdToken";

interface Options {
  initialQuery?: string;
}

export interface ManualEntry {
  address: string;
  placeId: "manual_entry";
  lat: number;
  lng: number;
}

export const usePlacesAutocomplete = ({ initialQuery }: Options = {}) => {
  const [query, setQuery] = useState(initialQuery ?? "");
  const [results, setResults] = useState<OptionProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const userIdToken = useUserIdToken();

  const handleSearch = useMemo(() => {
    return debounce(async (queryString) => {
      setLoading(true);

      const places = await googlePrediction(queryString, userIdToken)
        .then((results) =>
          results
            ?.filter((r) => !!r.structured_formatting.secondary_text)
            ?.map((c) => ({
              icon: <SvgLocation />,
              label: c.structured_formatting.main_text,
              caption: c.structured_formatting.secondary_text,
              value: c.place_id,
            })),
        )
        .catch(() => [
          {
            icon: <SvgLocation />,
            label: queryString,
            caption: "",
            value: "manual_entry",
          },
        ]);

      setResults(places);
      setLoading(false);
    }, 500);
  }, [userIdToken]);

  useEffect(() => {
    if (!!query) {
      handleSearch(query);
    } else {
      handleSearch.cancel();
      setResults([]);
    }

    return () => {
      handleSearch.cancel();
    };
  }, [handleSearch, query]);

  const getAddressDetails = useCallback(
    async (selected: OptionProps) => {
      const result = await googleGeocoder(selected.value, userIdToken)
        .then((results) => results?.[0])
        .catch(
          () =>
            ({
              address: selected.label,
              placeId: selected.value,
              lat: 0,
              lng: 0,
            }) as ManualEntry,
        );

      return result;
    },
    [userIdToken],
  );

  return { loading, setQuery, query, results, getAddressDetails };
};
