const UTMParams = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
];

export const getUTMParams = (search: string) => {
  const searchParams = new URLSearchParams(search);

  return Object.fromEntries(
    UTMParams.map((utmParam) => [utmParam, searchParams.get(utmParam)]),
  );
};
