import styled, { createGlobalStyle } from "styled-components";
import {
  CalendarIcon,
  LogoKnockout,
  SocialIcons,
  useEventConfig,
} from "../../../../Components";
import { Cards, Wave } from "./Components";
import { Footer } from "../../Components/Footer";
import { Theme, theme as mTheme } from "../../../../melodies-source/Theme";
import { useEffect } from "react";
import { HelpIconModal } from "../../../../Components/HelpModal";
import { useTranslation } from "react-i18next";

export const Swiper = () => {
  const config = useEventConfig();
  const { t } = useTranslation();

  const theme = {
    ...mTheme,
    fonts: {
      ...mTheme.fonts,
      families: ["Lato:wght@300;400;700;900"],
      poppins: "font-family: 'Lato', sans-serif;",
    },
  };

  const templateConfig = {
    primary: config?.colors?.dark || "#4d4db3",
    //primary: "#4d4db3",
    secondary: "#ffffff",
    text: "#ffffff",
    fontFamily: "'Lato', sans-serif",
    borderRadius: "22px",
  };

  const GlobalStyle = createGlobalStyle`
  #event-root-swiper {
    --template-text: ${templateConfig.text};
    --template-primary: ${templateConfig.primary};
    --template-secondary: ${templateConfig.secondary};
    --template-font-family: ${templateConfig.fontFamily};
    --template-border-radius: ${templateConfig.borderRadius};
    --max-action: ${templateConfig.primary};
  }
`;

  useEffect(() => {
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", templateConfig.secondary);
  }, [templateConfig.secondary]);

  return (
    //@ts-ignore
    <Theme theme={theme}>
      <GlobalStyle />
      <ColorWrap>
        <Container>
          <Header>
            <HeaderContent url={config?.header?.image}>
              <HeaderMenu>
                <LogoKnockout />
                <Text>
                  <span
                    style={{ display: "inline-block" }}
                    onClick={() => config.handleNavigateHome()}
                  >
                    {t("Exit Show")}
                  </span>
                  <HelpIconModal style={{ marginLeft: 12 }} />
                </Text>
              </HeaderMenu>
              <DateContainer>
                <CalendarIcon date={config.header.date} />
                <NameContainer>
                  <Text $name>{config?.header?.artistName}</Text>
                  <Text>{config?.header?.venue}</Text>
                </NameContainer>
              </DateContainer>
            </HeaderContent>
            <Wave
              style={{
                position: "absolute",
                zIndex: 2,
                bottom: -1,
                width: "100%",
                height: "auto",
                pointerEvents: "none",
              }}
            />
          </Header>
          <Wrapper>
            <Content>
              {!!config?.cards && <Cards cards={config?.cards} />}
            </Content>
          </Wrapper>
          <FooterContainer>
            <SocialIcons />
            <Footer style={{ color: "#757575" }} />
          </FooterContainer>
        </Container>
      </ColorWrap>
    </Theme>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
`;

const ColorWrap = styled.div`
  width: 100%;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow-y: auto;
`;

const Header = styled.div`
  display: flex;
  padding-top: calc(100% + 50px);
  position: relative;
`;

const HeaderContent = styled.div<{ url?: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  font-family: var(--template-font-family);
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.2) 10%,
      rgba(0, 0, 0, 0) 31%,
      rgba(0, 0, 0, 0) 40%,
      rgba(0, 0, 0, 0.2) 70%,
      #000 85%
    ),
    url(${({ url }) => url});
`;

const Wrapper = styled.div`
  z-index: 10;
  position: relative;
`;

const Content = styled.div`
  position: relative;
  margin-top: -64px;
`;

const HeaderMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Text = styled.div<{ $name?: boolean }>`
  color: var(--template-text);
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  text-shadow: 1px 1px 2px #373636;
  z-index: 10;
  font-family: var(--template-font-family);
  ${({ $name }) =>
    $name &&
    `
    font-size: 23px;
    margin-bottom: 5px;
  `};
  svg {
    margin-left: 10px;
  }
`;

const DateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 80px;
  z-index: 10;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-left: 10px;
`;

const FooterContainer = styled.div`
  margin-top: -10px;
`;
