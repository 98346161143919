import { KeyResponse } from "./api/secure";

export interface SecurePayload<T = unknown> {
  id: string;
  key: T;
  data: T;
  iv: T;
}

export async function encryptJsonObject({
  jsonObject,
  publicKeyPem,
}: {
  jsonObject: object;
  publicKeyPem: string;
}) {
  try {
    // Step 1: Serialize the JSON object to a string
    const jsonString = JSON.stringify(jsonObject);

    // Step 2: Generate a symmetric key (AES-GCM)
    const symmetricKey = await globalThis.crypto.subtle.generateKey(
      { name: "AES-GCM", length: 256 },
      true,
      ["encrypt", "decrypt"],
    );

    // Step 3: Encrypt the JSON string with the symmetric key
    const encoder = new TextEncoder();
    const iv = globalThis.crypto.getRandomValues(new Uint8Array(12)); // Initialization vector
    const encryptedDataBuffer = await globalThis.crypto.subtle.encrypt(
      { name: "AES-GCM", iv: iv },
      symmetricKey,
      encoder.encode(jsonString),
    );

    // Convert encrypted data from ArrayBuffer to Uint8Array
    const encryptedData = new Uint8Array(encryptedDataBuffer);

    // Step 4: Import the public key (from PEM format)
    const publicKey = await importPublicKey(publicKeyPem);

    // Step 5: Export the symmetric key and encrypt it with the public key (RSA-OAEP)
    const symmetricKeyRaw = await globalThis.crypto.subtle.exportKey(
      "raw",
      symmetricKey,
    );
    const encryptedSymmetricKeyBuffer = await globalThis.crypto.subtle.encrypt(
      { name: "RSA-OAEP" },
      publicKey,
      symmetricKeyRaw,
    );

    // Convert encrypted symmetric key from ArrayBuffer to Uint8Array
    const encryptedSymmetricKey = new Uint8Array(encryptedSymmetricKeyBuffer);

    // Step 6: Return the encrypted data and encrypted symmetric key
    return {
      encryptedData,
      encryptedSymmetricKey,
      iv, // Include the IV for decryption later
    };
  } catch (error) {
    console.error("Encryption failed:", error);
    throw error;
  }
}

export async function decryptJsonObject({
  encryptedData,
  decryptedSymmetricKey,
  iv,
}: {
  encryptedData: Uint8Array;
  decryptedSymmetricKey: Uint8Array;
  iv: Uint8Array;
}): Promise<object> {
  try {
    // Import the symmetric key from raw bytes
    const cryptoKey = await globalThis.crypto.subtle.importKey(
      "raw",
      decryptedSymmetricKey,
      { name: "AES-GCM" },
      true,
      ["decrypt"],
    );

    // Step 1: Decrypt the encrypted data using the decrypted symmetric key
    const decryptedBuffer = await globalThis.crypto.subtle.decrypt(
      { name: "AES-GCM", iv: iv },
      cryptoKey,
      encryptedData,
    );

    // Step 2: Convert the decrypted ArrayBuffer to a string
    const decoder = new TextDecoder();
    const jsonString = decoder.decode(decryptedBuffer);

    // Step 3: Parse the JSON string back into an object
    return JSON.parse(jsonString);
  } catch (error) {
    console.error("Decryption failed:", error);
    throw error;
  }
}

// Helper function to import a PEM-formatted RSA public key
async function importPublicKey(pem: string) {
  // Remove PEM formatting and ensure it's clean
  const pemHeader = "-----BEGIN PUBLIC KEY-----";
  const pemFooter = "-----END PUBLIC KEY-----";
  const pemContents = pem
    .replace(pemHeader, "")
    .replace(pemFooter, "")
    .replace(/\n/g, "")
    .replace(/\r/g, "")
    .trim();

  const binaryDerString = globalThis.atob(pemContents);
  const binaryDer = new Uint8Array(binaryDerString.length);
  for (let i = 0; i < binaryDerString.length; i++) {
    binaryDer[i] = binaryDerString.charCodeAt(i);
  }

  // Import the public key
  return await globalThis.crypto.subtle.importKey(
    "spki",
    binaryDer.buffer,
    { name: "RSA-OAEP", hash: "SHA-256" },
    true,
    ["encrypt"],
  );
}

export const DEFAULT_PRODUCTION_PUBLIC_KEY: KeyResponse = {
  id: "default:1",
  key: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAxRrlOJwPm8y6KhnBFtKC
kLdYkLSuO56nAUfoSIHe4S1aLUISp83v1seY4WT77IQWe1E08qLdNL5nbaqWeEgQ
/4z7Jfsa5V2muTp4goaKRdSprJBl7OZG7V//0oB8ps0qdMWY4qXI/RFSLu7zFufO
xSJaUci5ZIEU2XOE4H9WjYl/P6KQZ+A7jivmCBtlxLKP8re4RTW97D25+9LftoSn
8ERMYVsAGsDza/B8+h5Xd+dvrUIal1N31xrLSvphqpaddfBO0vn8RJcXqRcFnPuQ
O+HgHSD5yyKBxY9h0LN2aLbbGbG68M7M2jqGx1tV/A+vPHwLzCZfZ94SFK/TJan9
YQIDAQAB
-----END PUBLIC KEY-----`,
};

export const DEFAULT_STAGING_PUBLIC_KEY: KeyResponse = {
  id: "default:1",
  key: `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0OQSEQa0CSYWOtH7gr63
ptxwTPeh0EVpy5aF75sH+2wuamgU4D7m93pAQ7fVpuZX9S1oRlvxOCkEKVNYzvwo
TFSGAhUNX6GpC78SDKiFow266llGP+PtSWjOaFTK8MJviLn+Qc2oLzItmKJBI9Wg
pHZkdgwaEUbLUZoZDYRY9glf6/mD878s9ti4Xrfs+CIy4G8UTBQJ30BmePPhmSSY
ff2I1P81uN1aGtq6gk7+zuLhylWa+iHLlYt49tgfp8q4f5a7j2xcZzRzbRlCsgIc
i05PqTKKnU0Gh8gfzp+WQYdbDOqglyQy6XSDhexSnryOn5fsJk994WKV3+S/uMPw
nwIDAQAB
-----END PUBLIC KEY-----`,
};
