import styled, { createGlobalStyle } from "styled-components";
import {
  CalendarIcon as CalendarIconBase,
  SocialIcons,
  useEventConfig,
} from "../../../../Components";
import { Cards as CardsComponent } from "./Components";
import { Menu } from "./Menu";
import { Body2, H3 } from "../../../../melodies-source/Text";
import { theme as mTheme, Theme } from "../../../../melodies-source/Theme";
import { useEffect } from "react";
import { Footer } from "../../Components/Footer";
import clipMask from "./Components/ClipMask-Torn.svg";

export const Grunge = () => {
  const config = useEventConfig();
  const theme = {
    ...mTheme,
    fonts: {
      ...mTheme.fonts,
      families: ["Special+Elite", "Lato:wght@700"],
    },
  };

  const templateConfig = {
    text: "#ffffff",
    primary: config?.colors?.dark || "#D87516",
    secondary: "#ffffff",
    fontFamily: "'Special Elite', sans-serif",
    borderRadius: "0",
  };

  const GlobalStyle = createGlobalStyle`
  #event-root-grunge {
    --template-text: ${templateConfig.text};
    --template-primary: ${templateConfig.primary};
    --template-secondary: ${templateConfig.secondary};
    --template-font-family: ${templateConfig.fontFamily};
    --template-border-radius: ${templateConfig.borderRadius};
    --max-action: ${templateConfig.primary};
  }
  body {
    background: #000000;
  }
`;

  useEffect(() => {
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", "#000000");
  }, []);

  return (
    // @ts-ignore
    <Theme theme={theme}>
      <GlobalStyle />
      <Wrapper>
        <Container>
          <Banner>
            <Content>
              <Column style={{ flexGrow: 1 }}>
                <H3>{config.header.artistName}</H3>
                <Body2>{config.header.venue}</Body2>
              </Column>
              <Column>
                <CalendarIcon date={config.header.date} />
              </Column>
            </Content>
            <ImageMask>
              <Menu />
              <Gradient />
              <Gradient2 />
              <Image src={config.header.image} />
            </ImageMask>
          </Banner>
          {!!config?.cards && <CardsComponent cards={config.cards} />}
          <SocialIcons />
          <FooterStyled />
        </Container>
      </Wrapper>
    </Theme>
  );
};

const FooterStyled = styled(Footer)`
  color: #999999;
  span {
    color: #cccccc;
  }
`;

const CalendarIcon = styled(CalendarIconBase)`
  border-radius: 2px;
  border-radius: 255px 14px 225px 10px/15px 225px 15px 255px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  background: #000000;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  padding: 4px 0 20px;
  width: 100%;
  height: 100%;
  max-width: 600px;
  min-height: 0;
`;

const Banner = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 100%;
  overflow: hidden;
`;

const ImageMask = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  mask-image: url(${clipMask});
  mask-repeat: repeat-x;
  mask-size: 100% 100%;
`;

const Gradient = styled.div`
  background: linear-gradient(
    360deg,
    #000000 0%,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const Gradient2 = styled.div`
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 52px;
  z-index: 1;
`;

const Image = styled.img`
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
`;

const Content = styled.div`
  display: flex;
  padding: 0 12px 16px;
  z-index: 2;
  position: absolute;
  bottom: 0;
  width: 100%;

  ${H3}, ${Body2} {
    font-family: var(--template-font-family);
    color: #ffffff;
  }
  ${H3} {
    font-size: 24px;
    margin-bottom: 4px;
  }
`;
