/*
Example usage:

import { getIdToken, getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

const api = buildApi({
  // TODO: Fill these in with the actual endpoints
  fetchMap: {
    "artist.resolver": "",
    "artist.start": "",
    "search.streaming": "",
    "search.youtube": "",
  },
  getToken: async () => {
    const auth = getAuth();
    if (auth.currentUser) {
      return getIdToken(auth.currentUser);
    }

    console.warn(
      "An attempt is being made to call an API endpoint before we have an authenticated user",
    );

    return "";
  },
  httpsCallable: (name) => httpsCallable(getFunctions(), name),
});

api.call("setlivev3-analysis-analyzeImageFromURL", { url: "" }).then((res) => {
  res.data.colors;
});

api.call("artist.resolver", { url: "" }).then((res) => {
  if (res.data.success) {
    res.data.data.displayName;
  }
});
*/

import type { HttpsCallable } from "firebase/functions";

import { MerchMetadataRequest } from "../artists/analysis";
import {
  CheckSubdomainRequest,
  CheckSubResponse,
  ResolverRequest,
  ResolverResponse,
} from "./artist";
import { SocialRequest, SocialResponse } from "./artist/social";
import { ErrorCode, ErrorCodes } from "./base";
import { MetaMerchResponse, YoutubeRequest, YoutubeResponse } from "./search";
import { StreamingRequest, StreamingResponse } from "./search/streaming";

import { CustomOptInsRequest, CustomOptInsResponse } from "../privacy";
import { AiHandleRequest, AiHandleResponse } from "./ai";
import {
  AnalyzeImageFromURL,
  MerchandiseAnalysisResult,
} from "./artist/analysis";
import {
  ArtistRequest,
  RemoveRequest,
  SearchRequest,
  SingleResponse,
  TourResponse,
} from "./tour";
import { KeyRequest, ApiKeyResponse } from "./secure";

export { ErrorCodes };
export type { ErrorCode, KeyRequest };

/**
 * Use this type to documents the request and response types
 * for plain HTTP endpoints
 */
type Fetchable<RequestData, ResponseData> = HttpsCallable<
  RequestData,
  ResponseData
>;

export interface Routes {
  //
  // Fetchable endpoints
  //
  "artist.resolver": Fetchable<ResolverRequest, ResolverResponse>;
  "artist.start": Fetchable<SocialRequest, SocialResponse>;
  "search.youtube": Fetchable<YoutubeRequest, YoutubeResponse>;
  "search.streaming": Fetchable<StreamingRequest, StreamingResponse>;
  "ai.handle": Fetchable<AiHandleRequest, AiHandleResponse>;
  "artist.checksubdomain": Fetchable<CheckSubdomainRequest, CheckSubResponse>;
  "search.metamerch": Fetchable<MerchMetadataRequest, MetaMerchResponse>;
  "touring.search": Fetchable<SearchRequest, TourResponse>;
  "touring.artist": Fetchable<ArtistRequest, SingleResponse>;
  "touring.remove": Fetchable<RemoveRequest, ResolverResponse>;
  // "ai.concerts": Fetchable<AiConcertRequest, AiConcertResponseWrapped>;
  // "ai.prompts": Fetchable<AiPromptsRequest, AiPromptsResponseWrapped>;

  "secure.getPublicKey": Fetchable<KeyRequest, ApiKeyResponse>;

  //
  // Google Cloud Functions ("onCall")
  //
  "setfan-buildCustomOptIns": Fetchable<
    CustomOptInsRequest,
    CustomOptInsResponse
  >;
  "setlivev3-analysis-analyzeImageFromURL": AnalyzeImageFromURL;
  "setlivev3-analysis-merchandiselookup": MerchandiseAnalysisResult;
}

export type RequestPayloadData<K extends keyof Routes> =
  Routes[K] extends HttpsCallable<infer X> ? X : never;

export type ResponsePayloadData<K extends keyof Routes> = Awaited<
  ReturnType<Routes[K]>
>["data"] extends { success: boolean }
  ? Extract<Awaited<ReturnType<Routes[K]>>["data"], { success: true }>["data"]
  : Awaited<ReturnType<Routes[K]>>["data"];

export interface ApiProps {
  fetchMap: {
    [key in keyof Routes as key extends `${string}.${string}`
      ? key
      : never]?: string;
  };
  getToken: () => Promise<string | undefined | void>;
  httpsCallable: (name: string) => HttpsCallable;
}

export const buildApi = (props: ApiProps) => {
  const isFetchable = (key: string): key is keyof typeof props.fetchMap =>
    key in props.fetchMap;

  const call = async <K extends keyof Routes>(
    name: K,
    body: Parameters<Routes[K]>[0],
  ): Promise<Awaited<ReturnType<Routes[K]>>> => {
    if (isFetchable(name)) {
      const url = props.fetchMap[name];
      if (!url) {
        throw new Error(`API is missing route to endpoint ${name}`);
      }

      const token = await props.getToken().catch((error) => {
        console.warn("Error getting JWT for user", error);
      });

      const req = new Request(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body !== undefined ? JSON.stringify(body) : "",
      });

      if (token) {
        req.headers.set("Authorization", `bearer ${token}`);
      }

      const data = await fetch(req)
        .then((res) => res.json())
        .then((data) => ({ data } as Awaited<ReturnType<Routes[K]>>));
      return data;
    }
    return (await props.httpsCallable(name)(body)) as Awaited<
      ReturnType<Routes[K]>
    >;
  };

  return { call };
};
