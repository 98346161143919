import styled, { createGlobalStyle } from "styled-components";
import {
  CalendarIcon,
  SocialIcons,
  useEventConfig,
} from "../../../../Components";
import { Cards as CardsComponent } from "./Components";
import { Menu } from "./Menu";
import { Body2, H3 } from "../../../../melodies-source/Text";
import { Footer } from "../../Components/Footer";
import { theme as mTheme, Theme } from "../../../../melodies-source/Theme";
import { useEffect } from "react";

export const Cards = () => {
  const config = useEventConfig();
  const theme = {
    ...mTheme,
    fonts: {
      ...mTheme.fonts,
      families: ["Lato:wght@300;400;500;700;900"],
      poppins: "font-family: 'Lato', sans-serif;",
    },
  };

  const templateConfig = {
    text: "#ffffff",
    primary: config?.colors?.dark || "#39565d",
    secondary: config?.colors?.light || "#6cb8ca",
    fontFamily: "'Lato', sans-serif",
    borderRadius: "8px",
  };

  const GlobalStyle = createGlobalStyle`
  #event-root-cards {
    --template-text: ${templateConfig.text};
    --template-primary: ${templateConfig.primary};
    --template-secondary: ${templateConfig.secondary};
    --template-font-family: ${templateConfig.fontFamily};
    --template-border-radius: ${templateConfig.borderRadius};
    --max-action: ${templateConfig.primary};
  }
  body {
    background: var(--template-secondary);
  }
`;
  useEffect(() => {
    document
      .querySelector('meta[name="theme-color"]')
      ?.setAttribute("content", templateConfig.secondary);
  }, [templateConfig.secondary]);

  return (
    // @ts-ignore
    <Theme theme={theme}>
      <GlobalStyle />
      <Wrapper>
        <Container>
          <Menu />
          <HeaderWrapper>
            <ShowImage
              style={{ backgroundImage: `url(${config.header.image})` }}
            >
              <Content>
                <Column style={{ flexGrow: 1 }}>
                  <H3>{config.header.artistName}</H3>
                  <Body2>{config.header.venue}</Body2>
                </Column>
                <Column>
                  <CalendarIcon date={config.header.date} />
                </Column>
              </Content>
              <Gradient />
            </ShowImage>
          </HeaderWrapper>
          {!!config?.cards && <CardsComponent cards={config.cards} />}
          <SocialIcons />
          <Footer style={{ color: "#000000" }} />
        </Container>
      </Wrapper>
    </Theme>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  color: #fff;
  background: var(--template-secondary);
  align-items: center;
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  position: relative;
  padding: 20px 0;
  color: #fff;
  width: 100%;
  max-width: 600px;
  min-height: 0;
`;

const HeaderWrapper = styled.div`
  padding-bottom: 100%;
  position: relative;
  margin: 12px 20px 0;
`;

const ShowImage = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-shrink: 1;
  flex-grow: 0;
  overflow: hidden;
  ${H3}, ${Body2} {
    font-family: var(--template-font-family);
    color: #ffffff;
  }
  ${H3} {
    margin-bottom: 4px;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
  }
  ${Body2} {
    font-size: 14px;
    line-height: 18px;
  }
`;

const Gradient = styled.div`
  background: linear-gradient(
    360deg,
    #000000 15.69%,
    rgba(0, 0, 0, 0) 35.1%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
`;

const Content = styled.div`
  display: flex;
  padding: 0 20px 16px;
`;
