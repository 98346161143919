import { useNavigate, useParams } from "react-router-dom";
import { Client } from "typesense";
import { SearchParams } from "typesense/lib/Typesense/Documents";
import { getNode, getNodes } from "../Components";
import { DateTime } from "luxon";
import { MatchedEventDetail } from "./Landing";
import { useEffect } from "react";

const client = new Client({
  nodes: getNodes(process.env.REACT_APP_SEARCH_SERVER),
  nearestNode: getNode(process.env.REACT_APP_SEARCH_SERVER_NEAREST),
  apiKey: process.env.REACT_APP_TYPESENSE_KEY as string,
  logLevel: process.env.REACT_APP_ENV === "production" ? "silent" : "debug",
});

export const ArtistEvent = () => {
  const { artistId } = useParams();
  const navigate = useNavigate();

  const queryEvents = async (queryParams: SearchParams) => {
    let filter_by = `endedAt:=0 && deletedAt:=0`.concat(
      queryParams?.filter_by ||
        ` && startsAt:>=${DateTime.utc().minus({ hours: 12 }).toMillis()}`,
    );
    try {
      const result = await client
        .collections<MatchedEventDetail>("setlive_events")
        .documents()
        .search({
          limit_hits: 1,
          page: 1,
          per_page: 1,
          ...queryParams,
          filter_by,
          sort_by: "startsAt:asc",
        });

      if (result?.hits?.length) {
        return result.hits;
      } else {
        return [];
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (!artistId) {
      navigate("/");
    } else {
      queryEvents({
        query_by: "groupId",
        q: artistId,
      }).then((r) => {
        if (r?.[0]) {
          if (r[0]?.document?.version === 2) {
            navigate(`/e/${r[0].document.id}`);
          } else {
            window.location.href = `/event/${r[0].document.id}`;
          }
        } else {
          navigate("/");
        }
      });
    }
  }, [artistId]);

  return null;
};
