import { getAuth, onIdTokenChanged } from "firebase/auth";
import { useEffect, useState } from "react";

export default function useUserIdToken() {
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    const unsub = onIdTokenChanged(getAuth(), (user) =>
      user?.getIdToken().then((token) => setAccessToken(token)),
    );

    return () => unsub();
  }, []);

  return accessToken;
}
