export const googlePrediction = async (
  input: string,
  token: string,
): Promise<google.maps.places.AutocompletePrediction[]> => {
  const url = new URL(
    "/maps/api/place/autocomplete/json",
    process.env.REACT_APP_MAPS_URL!.replace(/\/$/, ""),
  );

  url.searchParams.set("input", input);

  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const json = await response.json();

  if (!json.success || json.data.status !== "OK") {
    throw new Error("Autocomplete request failed");
  }

  return json.data.predictions;
};

export const googleGeocoder = async (
  placeId: string,
  token: string,
): Promise<google.maps.GeocoderResult[]> => {
  const url = new URL(
    "/maps/api/geocode/json",
    process.env.REACT_APP_MAPS_URL!.replace(/\/$/, ""),
  );

  url.searchParams.set("place_id", placeId);

  const response = await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const json = await response.json();

  if (!json.success || json.data.status !== "OK") {
    throw new Error("Geocode request failed");
  }

  return json.data.results;
};
