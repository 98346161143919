import { DateTime } from "luxon";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { H4, P } from "../../melodies-source/Text";
import { Button } from "../../melodies-source/Button";
import styled from "styled-components";
import { ReactComponent as BannerSvg } from "../../Images/selectBanner.svg";
import { SvgHelpAlt } from "../../melodies-source/Svgs/HelpAlt";
import { SetLiveKnockoutLogo } from "../../Images/Logos";
import { SvgLocation } from "../../melodies-source/Svgs/Location";
import { SvgCalendar } from "../../melodies-source/Svgs/Calendar";
import { HelpIconModal } from "../../Components/HelpModal";

export const Select = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location?.state?.matchedEvents?.length) {
      //if you're somehow here without show info, go home
      navigate("/");
    }
  }, [location]);

  return (
    <Container>
      <Header>
        <Logo />
        <HelpIconModal />
      </Header>
      <Banner>
        <BannerSvg style={{ width: "auto", height: "auto" }} />
      </Banner>
      <Content>
        {(location as any)?.state?.matchedEvents?.map((e: any) => (
          <Wrapper>
            <EventContainer>
              <img src={e?.image} alt="band/album cover" />
              <Info>
                <H4>{e?.displayedName ?? e?.artistName}</H4>
                <div>
                  <SvgLocation color="#B54E38" style={{ width: 20 }} />
                  <P>{e?.venue}</P>
                </div>
                <div>
                  <SvgCalendar color="#1b0076" />{" "}
                  <P>{DateTime.fromMillis(e?.startsAt).toFormat("ff")}</P>
                </div>
              </Info>
            </EventContainer>
            <StartButton
              onClick={
                () =>
                  e?.version > 1
                    ? navigate(`/e/${e.id}`)
                    : (window.location.href = `/event/${e.id}`) //TODO: centralize this legacy routing switch
              }
            >
              Get Started
            </StartButton>
          </Wrapper>
        ))}
        <hr />
        <BackButton onClick={() => navigate("/")}>
          Choose A Different Show
        </BackButton>
        <P>
          By joining any show you agree to our{" "}
          <span onClick={() => navigate("/terms", { state: { back: true } })}>
            Terms of use
          </span>
        </P>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  color: #fff;
`;

const HelpIcon = styled(SvgHelpAlt)`
  width: 20px;
  height: 25px;
  color: #ffffff;
`;

const Logo = styled(SetLiveKnockoutLogo)`
  width: 16.15vw;
  max-width: 80px;
  height: auto;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 20px;
  background: ${({ theme }) => theme.colors.gradient};
  background-position: center;
  background-size: cover;
  height: 45px;
`;
const Banner = styled.div`
  width: 70%;
  max-width: 330px;
  margin: 25px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 15px;
  ${P} {
    color: #ffffff;
    font-size: 12px;
    font-weight: 300;
    span {
      font-weight: 500;
    }
  }
  hr {
    width: 100%;
    border: 0.5px solid #ffffff43;
  }
`;
const EventContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 15px;

  img {
    border-radius: 16px;
    border: 1px solid #fff;
    margin-right: 5px;
    width: 30%;
    max-width: 100px;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 35px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
  background-color: #fff;
  color: #000;
  padding: 10px 15px;
  border-radius: 16px;
  & > div {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  ${P} {
    color: #000000;
    text-align: left;
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
  }
  ${H4} {
    color: #000000;
    text-align: left;
    font-size: min(4.1vw, 16px);
    line-height: 20px;
    font-weight: 500;
  }
  svg {
    height: 15px;
    color: ${({ color }) => color};
    margin: -2px 2px -3px -6px;
  }
`;
const StartButton = styled(Button)`
  border-radius: 25px;
  background-color: #e71e3d;
  cursor: pointer;
  font-weight: 500;
  &:hover {
    background-color: #e71e3d;
  }
`;
const BackButton = styled(Button)`
  border-radius: 25px;
  width: 100%;
  background-color: #000;
  margin: 15px 0 7px;
  border-radius: 25px;
  border: 1.5px solid #e71e3d;
  color: #e71e3d;
  cursor: pointer;
  font-weight: 500;
  &:hover {
    background-color: #000;
  }
`;
