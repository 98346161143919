import {
  getFirestore,
  setDoc,
  doc,
  collection,
  DocumentData,
  where,
  query,
  Bytes,
} from "firebase/firestore";
import {
  firebaseApp,
  useAuth,
  useEventConfig,
  useLocationContext,
} from "../Components";
import { useCollectionData, useDocument } from "react-firebase-hooks/firestore";
import {
  createContext,
  ProviderProps,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import LogRocket from "logrocket";
import { useLocation } from "react-router-dom";

import { UserEventStates } from "@max/common/event";
import { encryptJsonObject, SecurePayload } from "@max/common/crypto";
import { DataState, UserData } from "@max/common/user";
import { getUTMParams } from "../Utils/utm";
import { getPublicKey } from "../Utils/crypto";

export interface DataInterface {
  data: DataState;
  payments: DocumentData[] | undefined;
  total: number;
  setData: React.Dispatch<React.SetStateAction<DataState>>;
  submit: (defaultValues?: Partial<DataState["values"]>) => Promise<void>;
  setValue: (val: { [key: string]: any }) => void;
}

const defaultState = {
  data: { status: UserEventStates.draft, values: {} },
  payments: [],
  total: 0,
  setData: () => {},
  submit: () => new Promise<void>(() => {}),
  setValue: () => {},
};

const DataContext = createContext<DataInterface>(defaultState);

export const MockDataProvider = (
  props: JSX.IntrinsicAttributes &
    Omit<ProviderProps<DataInterface>, "value"> & { data?: DataState | null },
) => {
  const [data, setData] = useState<DataState>({
    status: UserEventStates.draft,
    values: {},
  });
  const submit = () => Promise.resolve();
  const total = 0;

  const setValue = useCallback((vals: { [key: string]: any }) => {
    setData((prev) => ({
      ...prev,
      values: { ...prev.values, ...vals },
    }));
  }, []);

  const value: DataInterface = {
    data: props.data || data,
    setData,
    submit,
    payments: undefined,
    total,
    setValue,
  };
  return <DataContext.Provider {...props} value={value} />;
};

export const DataProvider = (
  props: JSX.IntrinsicAttributes & Omit<ProviderProps<DataInterface>, "value">,
) => {
  const { user } = useAuth();
  const { eventId } = useEventConfig();
  const { location } = useLocationContext();
  const db = getFirestore(firebaseApp);
  const userDocRef = doc(db, `set_fresh_events/${eventId}/users/${user!.uid}`);
  const [payments] = useCollectionData(
    query(
      collection(db, `set_fresh_events/${eventId}/payments`),
      where("uid", "==", user?.uid),
      where("status", "in", ["submitted", "succeeded"]),
    ),
  );
  const total = (payments || []).reduce((acc, curr) => acc + curr.amount, 0);
  const [userDoc] = useDocument(userDocRef);
  const [data, setData] = useState<DataState>({
    status: UserEventStates.draft,
    values: {},
  });
  const routerLocation = useLocation();
  useEffect(() => {
    if (userDoc !== undefined) {
      if (userDoc.data()) {
        setData((prev) => ({
          ...prev,
          status: userDoc?.data()?.status,
        }));
      }
    }
  }, [userDoc]);

  const submit: DataInterface["submit"] = async (defaultValues) => {
    const values: UserData = { ...defaultValues, ...data.values };
    values.email = values.email?.toLowerCase();

    const publicKey = await getPublicKey({
      id: eventId,
      item: "event",
    });

    const { encryptedData, encryptedSymmetricKey, iv } =
      await encryptJsonObject({
        jsonObject: values,
        publicKeyPem: publicKey.key,
      });

    const secure: SecurePayload<Bytes> = {
      id: publicKey.id,
      key: Bytes.fromUint8Array(encryptedSymmetricKey),
      data: Bytes.fromUint8Array(encryptedData),
      iv: Bytes.fromUint8Array(iv),
    };

    const input: DataState = {
      ...data,
      values,
      secure,
      queryParams: getUTMParams(routerLocation.search),
      status: UserEventStates.submitted,
    };

    return setDoc(userDocRef, input);
  };

  const setValue = useCallback((vals: { [key: string]: any }) => {
    setData((prev) => ({
      ...prev,
      values: { ...prev.values, ...vals },
    }));
  }, []);

  useEffect(() => {
    if (!data.values.coordinates && !!location) {
      setValue({ coordinates: location });
    }
  }, [location]);

  useEffect(() => {
    if (userDoc && user?.uid) {
      let vals = userDoc.data();
      LogRocket.identify(user.uid, {
        name: vals?.firstName + vals?.lastName || "",
        email: vals?.email || "",
        phone: vals?.phone || "",
      });
    }
  }, [userDoc]);

  return (
    <DataContext.Provider
      {...props}
      value={{ data, setData, submit, payments, total, setValue }}
    />
  );
};

export const useDataContext = () => useContext(DataContext);
