import {
  getFirestore,
  serverTimestamp,
  addDoc,
  collection,
  FieldValue,
} from "firebase/firestore";
import { firebaseApp } from "../Components";
import { createContext, ProviderProps, useContext } from "react";
import { useLocation } from "react-router-dom";

import { useAuth } from "./UserContext";

import {
  TrackingDoc,
  TrackingFunction,
  TrackingEvent,
} from "@max/common/analytics";
import TagManager from "react-gtm-module";
import { getUTMParams } from "../Utils/utm";

const removeUndefined = (data: unknown) => {
  if (typeof data === "object" && data?.constructor === Object) {
    Object.entries(data).forEach(([k, v]) => {
      v === undefined && delete data[k as keyof typeof data];
    });
  }
  return data;
};

export interface UserActionTrackingInterface {
  track: TrackingFunction;
}

const UserActionTrackingContext = createContext<UserActionTrackingInterface>(
  {} as UserActionTrackingInterface,
);

export const UserActionTrackingProvider = (
  props: JSX.IntrinsicAttributes &
    Omit<ProviderProps<UserActionTrackingInterface>, "value">,
) => {
  const { user } = useAuth();
  const location = useLocation();
  const db = getFirestore(firebaseApp);
  const ref = collection(db, "set_fresh_analytics");

  const value = {
    track: async (event: TrackingEvent) => {
      if (!user?.uid) {
        return;
      }

      // Remove any undefined values from the context
      removeUndefined(event.context);

      TagManager.dataLayer({
        dataLayer: {
          event: `set.${event.event}`,
          ...event.context,
        },
      });

      const doc: TrackingDoc<FieldValue> = {
        uid: user.uid,
        path: location.pathname,
        createdAt: serverTimestamp(),
        queryParams: getUTMParams(location.search),
        ...event,
      };

      addDoc(ref, doc);
    },
  };

  return <UserActionTrackingContext.Provider {...props} value={value} />;
};

export const useUserActionTrackingContext = () => {
  const ctx = useContext(UserActionTrackingContext);
  if (!!ctx?.track) {
    return ctx;
  }
  return { track: () => {} };
};
